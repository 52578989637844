import React from "react";
import GetStartedForm from "./GetStartedForm";
import { motion, AnimatePresence } from "framer-motion";

const GetStartedModal = ({ openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="px-5">
      <AnimatePresence>
        {openModal && (
          <motion.div
            onClick={closeModal}
            className="z-[9999] h-full w-full bg-[#4A3373] bg-opacity-90 fixed top-0 left-0 z-50 flex justify-center items-center px-5"
          >
            <div className="flex-col">
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="rounded-2xl p-4 relative"
                onClick={(e) => e.stopPropagation()} // Prevent click event propagation
              >
                <GetStartedForm
                  subheading="Proven expertise in affordable digital solutions."
                  heading="Book a Free Consultation."
                  isAnimate={false}
                  isVisible={false}
                  close={setOpenModal}
                  closeModal={closeModal}
                />
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GetStartedModal;
