const serviceDropDown = [
  {
    linkTag: "/services/web-development",
    name: "Web Design And Development",
  },
  {
    linkTag: "/services/mobile-app-development",
    name: "Mobile App Development",
  },
  // {
  //   linkTag: "/services/hosting-services",name: "Hosting Services",
  // },
  {
    linkTag: "/services/brand-development-services",
    name: "Brand Development & Strategy",
  },

  {
    linkTag: "/services/social-media-management",
    name: "Social Media Management",
  },
  {
    linkTag: "/services/social-media-marketing",
    name: "Social Media Marketing",
  },


  // { linkTag: "/services/seo-services", name: "Search Engine Optimization" },
  // { linkTag: "/services/ppc", name: "Pay-Per-Click" },
  // {
  //   linkTag: "/services/content-management-and-marketing",
  //   name: " Content management & Marketing",
  // },
  { linkTag: "/services/graphic-designing", name: "Graphic Designing" },
  {
    linkTag: "/services/video-editing-and-animation",
    name: "Video Editing & Animation",
  },
  // { linkTag: "/services/accounting", name: "Accounting" },
  //{
   // linkTag: "/services/accounting",
   // name: "Accounting Service",
  //},
  {
    linkTag: "/services/ppc",
    name: "PPC Management Services",
  },
  {
    linkTag: "/services/seo-services",
    name: "Strategic SEO Services",
  },
  {
    linkTag: "/services/hosting-services",
    name: "Hosting Services",
  },
];

const menuItems = [
  { name: "Home", linkTag: "/" },
  { name: "Services", linkTag: "/services/print-logo-branding" },
  { name: "Portfolio", linkTag: "/portfolio" },
  { name: "Case Study", linkTag: "/case-study" },
  { name: "Testimonials", linkTag: "/testimonials" },
  { name: "Blogs", linkTag: "/blogs" },
  { name: "About", linkTag: "/about" },
  { name: "Contact", linkTag: "/contact" },
];

const containerVariantsDesktop = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0,
      duration: 0.2,
      type: "spring",
      stiffness: 120,
      damping: 10,
    },
  },
};

import React, { useState, useEffect, useCallback, Fragment } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import GetStartedModal from "../GetStartedModal";
import Image from "next/image";
import styles from "../../styles/header.module.css";

// import logoBefore from 'public/techcelerant-logo-desktop-before.webp'
// import logoAfter from 'public/techcelerant-logo-desktop-after.webp'

import Cookies from "js-cookie";
import { useRouter } from "next/router";
//import CountDown from "@/components/Discounts/CountDown";
//import SideBarButton from "../cta/SideBarButton";
import SideBarButton2 from "../cta/SideBarButton2";

const DesktopHeader = () => {
  const [showModal, setShowModal] = useState();
  const [videoPlayed, setVideoPlayed] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledSecond, setIsScrolledSecond] = useState(false);
  const [isActiveTab, setActiveTab] = useState("Home");
  const [isOpen, setIsOpen] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const router = useRouter();

  const isTrueCookie = Cookies.get("openFormModal");

  useEffect(() => {
    const checkCookies = () => {
      if (isTrueCookie === "true") {
        setShowModal(false);
      } else {
        setShowModal(true);
        Cookies.set("openFormModal", "true", { expires: 1 });
      }
    };

    checkCookies();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleMouseEnter = useCallback(() => {
    if (timerId) clearTimeout(timerId);
    setTimerId(setTimeout(() => setIsOpen(true), 500));
  }, [timerId]);

  const handleMouseLeave = useCallback(() => {
    if (timerId) clearTimeout(timerId);
    setTimerId(setTimeout(() => setIsOpen(false), 500));
  }, [timerId]);

  const handleListMouseLeave = useCallback(() => {
    setTimerId(setTimeout(() => setIsOpen(false), 500));
  }, []);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset;
    const threshold = 350;
    const thresholdSecond = 300; // adjust this value as needed

    setIsScrolled(scrollTop > threshold);
    setIsScrolledSecond(scrollTop > thresholdSecond);

    setIsOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const servicesLinkHandler = (linktag) => {
    setIsOpen(false);
    window.location.href = linktag;
  };

  const handleRouteChange = (linktag) => {
    setIsOpen(false);

    window.location.href = linktag;
  };

  // Function to handle GIF playback completion

  useEffect(() => {
    setTimeout(() => {
      setVideoPlayed(true);
    }, 4000);
  }, []);
  const [linkbool, setlinkbool] = useState(false)
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/get-a-quote') || currentUrl.includes('/ContactPagenew')) {
      setlinkbool(true)
    }
  }, []);

 /* const countdown = {
    date: process.env.NEXT_PUBLIC_DISCOUNT_BANNER_DATE,
    hours: 18,
    seconds: 46,
    minutes: 33,
    days: 27,
  };
*/
  //const isCountDownShow = process.env.NEXT_PUBLIC_SHOW_DISCOUNT_BANNER;

  return (
    <Fragment>
     {/*} {isCountDownShow && <CountDown data={countdown} />} */}
     <SideBarButton2/> 
      <div className={`${styles.main_container} hidden sm:block max-w-[100vw]`}>
        
        <motion.div
          animate={{ y: isScrolled ? 0 : isScrolledSecond ? -100 : 0 }}
          initial={{ y: 0 }}
          transition={{ type: "tween", duration: 1.5 }}
          className={` ${styles.main}  ${
            isScrolled ? styles.main_scrolled : styles.main_scrolled_before
          }`}
        >

          <div
            onMouseLeave={handleListMouseLeave}
            className={styles.header_container}
          >
            {/* logo starts here */}

            <div className={styles.container_one}>
              
              <Link href="/">
              {/*}  <p className="text-white absolute z-[-1]">Home desktop</p> */}

                {isScrolled ? (
                  <Image
                    width={200}
                    height={200}
                    src="/techcelerant-logo-desktop-before.webp"
                    alt="main logo after"
                    className={styles.logo_container_after}
                  />
                ) : !videoPlayed ? (
                  <Image
                    width={200}
                    height={200}
                    autoPlay
                    poster="/techcelerant-logo-desktop-before.webp"
                    playsInline
                    muted
                    src="/techcelerant-logo-desktop-before.webp"
                    alt="main logo before"
                    className={styles.logo_container_before}
                  />
                ) : (
                  <Image
                    width={200}
                    height={200}
                    poster="/mobile-ph-logo.webp"
                    autoPlay
                    playsInline
                    muted
                    loop
                    src="/techcelerant-logo-desktop-after.webp"
                    alt="main logo before"
                    className={styles.logo_container_before}
                  />
                )}
              </Link>
            </div>
            {/* logo ends here */}

            {/* nav items start here */}
            <div className={styles.container_second}>
              <nav
                className={` ${styles.nav_container} ${
                  isScrolled ? "" : styles.nav_container_before
                }   rounded-full `}
              >
                {menuItems.map((result, index) => (
                  <div key={index} onClick={() => setActiveTab(result.name)}>
                    {result.name === "Services" ? (
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={`font-gray-200 text-[0.8rem] px-[1vw] cursor-pointer py-[10px]   ${
                          isScrolled ? "" : ""
                        } relative ${router.asPath.includes("/services") && !isScrolled ? "px-1 bg-white text-black font-bold transform transition duration-300  rounded-full" : router.asPath.includes("/services") && isScrolled ? "bg-gradient-to-b from-[#624da0] to-[#984d7d] rounded-full text-white px-5 py-[4px] transform transition duration-300" : isScrolled ? "text-black font-bold " : "text-white "} `}
                      >
                        {result.name}
                        {/* services */}
                        {isOpen && (
                          <motion.div
                            variants={containerVariantsDesktop}
                            initial="hidden"
                            animate="visible"
                            className="relative"
                          >
                            <div className="absolute top-[0.75rem] -left-[2rem] ">
                              <ul className=" bg-white to-black w-[300px] mx-2   py-3 rounded-lg flex flex-col items-start  shadow-lg shadow-slate-500 ">
                                {serviceDropDown.map((result) => (
                                  <li
                                    className="hover:bg-gradient-to-b hover:from-[#624da0]   hover:to-[#984d7d] py-2 hover:text-white  w-full text-black"
                                    key={result.name}
                                  >
                                    <p
                                      onClick={() =>
                                        servicesLinkHandler(result.linkTag)
                                      }
                                    >
                                      <p className=" hover:font-bold px-2   font-semibold">
                                        {result.name}
                                      </p>
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </motion.div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => handleRouteChange(result.linkTag)}
                      >
                        <p
                          className={` text-[0.8rem] relative  ${
                            isScrolled
                              ? "text-black px-[0.5vw]"
                              : "text-gray-800 px-[0.5]  py-[10px] px-2 "
                          } ${result.linkTag === router.asPath && isScrolled ? "bg-gradient-to-b from-[#624da0]  to-[#984d7d] text-white font-bold px-4 py-[4px]  rounded-full " : isScrolled ? "text-black font-bold " : result.linkTag === router.asPath && !isScrolled ? "bg-white  text-black font-bold px-4 rounded-full " : "text-white"}`}
                        >
                          {result.name}{" "}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </nav>
            </div>

            {/* end */}

            {/* get started starts here */}

            <div className={styles.container_third}>
              <div
                onClick={() => toggleModal()}
                className={styles.getStartedModel}
              >
                <span className={styles.call_to_action_buttons}>
                  Get Started
                </span>
              </div>
            </div>
            {/* end */}
          </div>
          {/* div end */}
        </motion.div>
      </div>
      {linkbool ? (   <></>
):(<GetStartedModal openModal={showModal} setOpenModal={setShowModal}/>)}
    </Fragment>
  );
};

export default DesktopHeader;
